import { Button, Divider, Grid, Typography } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import * as yup from 'yup';
import CustomAutoComplete from "../../../components/admin/common/AutoComplete/CustomAutoComplete";
import { showErrorNotification, showSuccessNotification, showWarningNotification } from "../../../components/admin/common/NotiService";
import OZFCheckbox from "../../../components/admin/common/OZFCheckbox";
import OZInput from "../../../components/admin/common/OZInput";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import OZSelect from "../../../components/admin/common/OZSelect";
import SimpleSwitch from "../../../components/admin/common/OZSwitch/SimpleSwitch";
import { ScrollToFieldError } from "../../../components/admin/common/ScrollToFieldError";
import { addsubadmin, getAgentGroupsList, getProfileList, getSipBargeExtensionDropdown, getSkillsDropdown, getUserCampaignList, getUserLoggedIn, updatesubadminById } from "../../../services/api-service";
import { ADMIN_FORM_MODES } from "../../../utils/constants";
import { checkRoles } from "../../../utils/util";

const styles = {
  box: {
    position: 'relative',
    backgroundColor: '#edf5ff',
    height: '42px',
    margin: '10px',
    alignItems: 'center',
    boxShadow: '0px 9px 12px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    display: 'inline-block',
    padding: '12px',
  },
  arrow: {
    position: 'absolute',
    top: '-9px',
    left: '6%',
    transform: 'translateX(-50%)',
    width: '0',
    height: '0',
    borderLeft: '20px solid transparent',
    borderRight: '20px solid transparent',
    borderBottom: '22px solid #edf5ff',
  },
  text: {
    fontWeight: 500,
  },
};

const AddSubUser = (props) => {

  let styleFieldHeader = useMemo(
    () => ({ fontSize: "14px", fontWeigth: 400, marginLeft: '1%', marginTop: '-0.5%' }),
    []);
  const [loading, setLoading] = useState(false);
  const [profileListData, setProfileListData] = useState([]);
  const [agentGrpListData, setAgentGrpListData] = useState([]);
  const [campaignListData, setCampaignListData] = useState([]);
  const [skillsListData, setSkillsListData] = useState([]);
  const [disableForm, setDisableForm] = useState(props?.mode != ADMIN_FORM_MODES.add || false);
  const [sipBargeExtensionData, setSipBargeExtensionData] = useState([]);
  let [updatedRowData, setUpdatedRowData] = useState({});

  useEffect(() => {
    getProfileListData();
    getGroupsList();
    getCampaignList();
    getSkillsList();
    if (checkRoles("ROLE_ADMIN")) {
      getSipBargeExtensionOptions();
    }
  }, [])

  async function getProfileListData() {
    setLoading(true);
    try {
      getProfileList()
        .then((resp) => {
          setProfileListData(resp.Data);
          setLoading(false);
        })
        .catch((e) => {
          setProfileListData([]);
          setLoading(false);
        });
    } catch (e) {
      setProfileListData([]);
      showErrorNotification("Error in profile list");
      setLoading(false);
    }
  };

  async function getGroupsList() {
    setLoading(true);
    try {
      getAgentGroupsList()
        .then((resp) => {
          setAgentGrpListData(resp?.Data || []);
          setLoading(false);
        })
        .catch((e) => {
          setAgentGrpListData([]);
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in groups list");
      setAgentGrpListData([]);
      setLoading(false);
    }
  };

  async function getSkillsList() {
    setLoading(true);
    try {
      getSkillsDropdown().then((resp) => {
        const respData = resp?.Data;
        setSkillsListData(respData || []);
        setLoading(false);
      }).catch((e) => {
        setSkillsListData([]);
        setLoading(false);
      });
    } catch (e) {
      showErrorNotification("Error in Skills list");
      setSkillsListData([]);
      setLoading(false);
    }
  };

  async function getCampaignList() {
    setLoading(true);
    try {
      getUserCampaignList()
        .then((resp) => {
          const respData = resp?.Data;
          setCampaignListData(respData || []);
          setLoading(false);
        })
        .catch((e) => {
          setCampaignListData([]);
          setLoading(false);
        });
    } catch (e) {
      showErrorNotification("Error in groups list");
      setCampaignListData([]);
      setLoading(false);
    }
  };

  async function getSkillsList() {
    setLoading(true);
    try {
      getSkillsDropdown().then((resp) => {
        const respData = resp?.Data;
        setSkillsListData(respData || []);
        setLoading(false);
      }).catch((e) => {
        setSkillsListData([]);
        setLoading(false);
      });
    } catch (e) {
      showErrorNotification("Error in Skills list");
      setSkillsListData([]);
      setLoading(false);
    }
  };

  async function getSipBargeExtensionOptions() {
    try {
      getSipBargeExtensionDropdown()
        .then((resp) => {
          const extractedValues = resp.Data.map(item => item.name);
          setSipBargeExtensionData(extractedValues);
        })
        .catch((e) => {
          setSipBargeExtensionData([]);
        });
    } catch (e) {
      showErrorNotification("Error in Sip Barge Extension list");
    }
  }

  function setUpdatedData(data) {
    let updatedData = structuredClone(data);
    if (props?.mode !== ADMIN_FORM_MODES.add) {
      updatedData.confirmPassword = data.password;
      if (data?.appProfileUser) {
        updatedData.appProfileUser = data?.appProfileUser[0];
      }
      setUpdatedRowData(updatedData);
    }
  };

  useEffect(() => {
    setUpdatedData(props.rowData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.rowData]);

  const DataForm = (props) => {
    const onClickEdit = () => {
      setDisableForm(false);
    }

    const onClickDiscardEdit = () => {
      setDisableForm(true);
    }

    let initData = JSON.parse(localStorage.getItem('subUserForm')) || (props?.mode != ADMIN_FORM_MODES.add && updatedRowData) || {
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      passwordHint: '',
      appPlanUser: null,
      appProfileUser: null,
      userGroups: [],
      userCampaigns: [],
      userSkills: [],
      accountLocked: false,
      enabled: true,
      accountExpired: false,
      credentialsExpired: false,
      continueBarge: false,
      sipLocationExtension: '',
      sipBargeExtension: '',
      extension: '',
      phoneNumber: '',

      //non present fields
      appModules: [],
      userTimezone: "",
      serverTimezone: "Asia/Kolkata",
      allowedIp: '',
      screenPopMode: '',
      callbackUrl: '',
      website: '',

      userLocation: "AddressData",//NotRequired Field
      city: "kakinada",//required Field
      province: "sttate",//required Field
      postalCode: "533001",//required Field
      country: "IN",//required Field
    }

    const validationSchema = useMemo(() => (yup.object({
      username: yup
        .string('Enter Sub Username')
        .test("maxDigits",
          "Sub Username should be between 3 to 50 alphanumeric characters long and allows special characters like .,@,_,-",
          (number) => String(number).length >= 3 && String(number).length <= 50)
        .matches(/^([A-Za-z\d]((\.|@|_|\-)?[A-Za-z\d])+)$/, "Sub Username should be between 3 to 50 alphanumeric characters long and allows special characters like .,@,_,-")
        .required('Sub Username is required'),
      firstName: yup
        .string("Enter First Name")
        .test("maxDigits",
          "First Name should be between 3 to 50 alphanumeric characters long and allows special characters like _",
          (number) => String(number).length >= 3 && String(number).length <= 50)
        .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "First Name should be between 3 to 50 alphanumeric characters long and allows special characters like _")
        .required('First Name is required'),
      lastName: yup
        .string("Enter Last Name")
        .test("maxDigits",
          "Last Name should be between 3 to 50 alphanumeric characters long and allows special characters like _",
          (number) => String(number).length >= 3 && String(number).length <= 50)
        .matches(/^([A-Za-z0-9]((_)?[A-Za-z0-9])+)$/, "Last Name should be between 3 to 50 alphanumeric characters long and allows special characters like _")
        .required('Last Name is required'),
      email: yup
        .string("Enter Last Name")
        .email('enter valid email')
        .test("maxDigits",
          "E-Mail should be a valid email.",
          (number) => String(number).length >= 5 && String(number).length <= 50)
        .required('Email is required'),
      password: props?.mode === ADMIN_FORM_MODES.add
        ? yup
          .string("Enter Password")
          .required('Password is required')
          .test(
            "maxDigits",
            "The password must not contain spaces. The password must be 7 to 50 characters, including an uppercase letter, a lowercase letter, and alphanumeric characters.",
            (value) => String(value).length >= 7 && String(value).length <= 50
          )
          .matches(
            /^((?!.*[\s])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-_!@#$%^&*()+{}[\]:;<>,.?~]).{7,50})$/,
            "The password must not contain spaces. The password must be 7 to 50 characters, including an uppercase letter, a lowercase letter, and alphanumeric characters."
          )
        : yup
          .string()
          .required('Password is required')
          .test(
            'password-valid',
            'The password must be 7 to 50 characters, including an uppercase letter, a lowercase letter, and alphanumeric characters.',
            (value) => {
              // Use the logic from `getValidationInEditMode`

              if (value === props?.rowData?.password) return true;
              if (!value) return false;

              // Apply regex validation for the password
              const regex = /^((?!.*[\s])(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[-_!@#$%^&*()+{}[\]:;<>,.?~]).{7,50})$/;
              return regex.test(value);
            }
          ),
      confirmPassword: yup
        .string("Enter Confirm Password")
        .required('Confirm Password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'), // Added this validation to check if confirmPassword matches the password field
      passwordHint: yup
        .string("Enter Password Hint")
        .required('Password Hint is required'),
      appProfileUser: yup
        .object()
        .nullable()
        .required("Profile Selection is required."),
      extension: yup
        .string("Enter Extension")
        .required('Extension is required'),
      phoneNumber: yup
        .string("Enter Phone Number")
        .required('Phone Number is required'),
    })), []);

    async function addUser(data) {
      setLoading(true);
      try {
        let finalData = structuredClone(data);

        finalData.appProfileUser = [data.appProfileUser]

        const loggedinUser = await getUserLoggedIn();
        const loggedinUserData = loggedinUser?.Data[0];

        finalData.userTimezone = loggedinUserData.userTimezone;
        finalData.serverTimezone = loggedinUserData.serverTimezone;
        finalData.allowedIp = loggedinUserData.allowedIp;
        finalData.screenPopMode = loggedinUserData.screenPopMode;
        finalData.callbackUrl = loggedinUserData.callbackUrl;

        addsubadmin(finalData)
          .then((resp) => {
            if (resp.Status === "Success") {
              showSuccessNotification(`Sub User ${finalData.username} Created Successfully`);
              props.onClose();
              props.updateFunction();
              setLoading(false);
            }
            else {
              showWarningNotification(resp.Message.allowedIp || resp.Message.username || resp.Message.firstName || resp.Message.lastName || resp.Message.email || resp.Message.screenPopMode || resp.Message.chatApiKey || resp.Message.callsApiKey || resp.Message.extension || resp.Message.phoneNumber ||
                resp.Message.apiKey || resp.Message.city || resp.Message.province || resp.Message.postalCode || resp.Message.country || resp.Message.userTimezone || resp.Message.urlMap || resp.Message.serverTimezone || resp.Message.dialerMapping || resp.Message.roles || resp.Message);
              setLoading(false);
            }
          })
          .catch((e) => {
            showErrorNotification("Error adding Sub User");
            setLoading(false);
          });
      } catch (e) {
        showErrorNotification("Error in adding Sub User");
        setLoading(false);
      }
    };

    async function updateUser(data) {
      setLoading(true);
      try {
        let finalData = structuredClone(data);
        finalData.appProfileUser = [data.appProfileUser]
        let isPasswordUpdate = false;
        if (props?.rowData?.password != data?.password) {
          isPasswordUpdate = true;
        }
        finalData.is_update = isPasswordUpdate;
        updatesubadminById(finalData)
          .then((resp) => {
            if (resp.Status === "Success") {
              showSuccessNotification(`Sub User ${finalData.username} Updated Successfully`);
              props.onClose();
              props.updateFunction();
              setLoading(false);
            }
            else {
              showWarningNotification(resp.Message.allowedIp || resp.Message.username || resp.Message.firstName || resp.Message.lastName || resp.Message.email || resp.Message.screenPopMode || resp.Message.chatApiKey || resp.Message.callsApiKey || resp.Message.extension || resp.Message.phoneNumber ||
                resp.Message.apiKey || resp.Message.city || resp.Message.province || resp.Message.postalCode || resp.Message.country || resp.Message.userTimezone || resp.Message.urlMap || resp.Message.serverTimezone || resp.Message.dialerMapping || resp.Message.roles || resp.Message);
              setLoading(false);
            }
          })
          .catch((e) => {
            showErrorNotification("Error updating Sub User");
            setLoading(false);
          });
      }
      catch (e) {
        showErrorNotification("Error updating Sub User");
        setLoading(false);
      }
    };


    function getAccessToShowConfig(profile, typeAsked){
      let data = structuredClone(profile);
      let tempData = JSON.parse(data?.profileSetting);
      let flag = false;

      Object.keys(tempData).forEach(key => {
        const value = tempData[key];

        if(key === 'viewAccess'){
          if(value.includes(typeAsked)){
            flag = true;
          }
        }
        if(key === 'editAccess'){
          if(value.includes(typeAsked)){
            flag = true;
          }
        }
        if(key === 'fullAccess'){
          if(value.includes(typeAsked)){
            flag = true;
          }
        }
        if(key === 'dataUpload'){
          if(value.includes(typeAsked)){
            flag = true;
          }
        }
      });
      return flag;
    }

    return (
      <div className="ozAddSubAdminForm">
        <Formik
          initialValues={initData}
          validateOnMount
          validationSchema={validationSchema}
          onSubmit={(data) => {
            localStorage.setItem('subUserForm', JSON.stringify(data))
            if (props?.mode === ADMIN_FORM_MODES.add) {
              addUser(data);
            } else {
              updateUser(data);
            }
          }}
        >
          {(propsForm) => (
            <form
              onSubmit={propsForm.handleSubmit}
              id="userForm"
              className="ozcw-scrollbar"
            >
              <Grid container={true} display="flex">
                <div
                  style={{
                    marginLeft: "80px",
                  }}
                >
                  <Typography
                    className="oz-page-header"
                    onClick={props.onClose}
                  >
                    {" "}
                    Sub User / Add Sub User{" "}
                  </Typography>
                  <Typography className="oz-page-heading">
                    {" "}
                    {props?.mode === ADMIN_FORM_MODES.add ? "Sub User Creation" : props?.rowData?.username}{" "}
                  </Typography>
                  <div
                    style={{
                      paddingTop: "16px",
                      paddingBottom: "16px",
                      borderRadius: "8px",
                      backgroundColor: "#FFFFFF",
                      width: "81vw",
                    }}
                  >
                    <Grid
                      className="fieldsStyle"
                      container
                      spacing="20px"
                    >
                      {props?.mode != ADMIN_FORM_MODES.add && props?.editAccess && (
                        <>
                          <Typography
                            style={{ fontSize: '14px', marginLeft: 'auto', marginRight: '2%', color: '#3D8BF8' }}
                            className="oz-page-header"
                            onClick={() => {
                              if (disableForm) {
                                onClickEdit();
                              } else {
                                propsForm.resetForm({ values: props?.rowData });
                                onClickDiscardEdit();
                              }
                            }}
                          >
                            {" "}
                            {disableForm ? 'Edit' : 'Discard Edit'}{" "}
                          </Typography>
                        </>
                      )}
                      <Grid
                        className="fieldsStyle"
                        container
                        spacing="20px"
                      >
                        <Grid item xs={5}>
                          <OZInput
                            name="username"
                            label="Sub Username *"
                            placeholder="Sub Username"
                            disabled={props?.mode != ADMIN_FORM_MODES.add}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={5}>
                        <OZInput
                          name="firstName"
                          label="First Name *"
                          placeholder="First Name"
                          disabled={disableForm}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <OZInput
                          name="lastName"
                          label="Last Name *"
                          placeholder="Last Name"
                          disabled={disableForm}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <OZInput
                          name="email"
                          label="Email *"
                          placeholder="Email Address"
                          disabled={disableForm}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <OZSelect
                          name="appProfileUser"
                          label="Choose Sub User Profile *"
                          options={profileListData}
                          optionLabel="name"
                          placeholder="Select"
                          disabled={disableForm}
                        />
                      </Grid>
                      {checkRoles("ROLE_ADMIN") ? (
                        <Grid item xs={5}>
                          <OZSelect
                            disabled={disableForm}
                            name="sipBargeExtension"
                            label="SIP Barge Extension"
                            optionLabel="displayCRMIn"
                            options={sipBargeExtensionData}
                            placeholder="Enter Extension URL"
                          />
                        </Grid>
                      ) : (
                        <></>
                      )}
                      {checkRoles("ROLE_ADMIN") ? (
                        <Grid item xs={5}>
                          <OZInput
                            disabled={disableForm}
                            name="sipLocationExtension"
                            label="SIP Location Extension"
                            placeholder="Enter Location"
                          />
                        </Grid>
                      ) : (
                        <></>
                      )}
                      <Grid item xs={3.3}>
                        <OZInput
                          name="extension"
                          label="Extension *"
                          placeholder="Extension"
                          disabled={disableForm}
                        />
                      </Grid>
                      <Grid item xs={3.3}>
                        <OZInput
                          name="phoneNumber"
                          label="Phone Number *"
                          placeholder="***********"
                          disabled={disableForm}
                        />
                      </Grid>
                      <Grid item xs={3.4}>
                          <OZInput
                            name="website"
                            label="Website"
                            placeholder="Website"
                            disabled={disableForm}
                          />
                        </Grid>
                      <Grid item xs={3.3}>
                        <OZInput
                          name="password"
                          label="Password *"
                          placeholder="Password"
                          type='password'
                          disabled={disableForm}
                        />
                      </Grid>
                      <Grid item xs={3.3}>
                        <OZInput
                          name="confirmPassword"
                          label="Confirm Password *"
                          placeholder="ConfirmPassword"
                          type="password"
                          disabled={disableForm}
                        />
                      </Grid>
                      <Grid item xs={3.4}>
                        <OZInput
                          name="passwordHint"
                          label="Password Hint *"
                          placeholder="PasswordHint"
                          disabled={disableForm}
                        />
                      </Grid>
                      <Grid
                        className="fieldsStyle"
                        container
                        spacing="20px"
                      >
                        <Grid item xs={5} style={{ marginLeft: '0.5%' }}>
                          {checkRoles("ROLE_CONTINUE_BARGE") ? (
                            <div className='oz-user-form-view-sub' style={{ marginLeft: '0% ! important' }}>
                              <div>
                                <SimpleSwitch name='continueBarge' disabled={disableForm} checked={propsForm?.values?.continueBarge}
                                  onChange={(e) => { propsForm?.setFieldValue('continueBarge', e.target.checked) }}
                                />
                              </div>
                              <Typography sx={styleFieldHeader} color={disableForm ? '#99A0A8' : '#212121'}>
                                Continuous Barge-in
                              </Typography>
                            </div>
                          ) : null}
                        </Grid>
                      </Grid>
                      <div
                        style={{
                          paddingTop: "32px",
                          color: "#212121",
                          fontSize: "18px",
                          display: "flex",
                          flexDirection: "column",
                          paddingLeft: "20px",
                          lineHeight: "21px",
                          fontWeight: 400,
                        }}
                      >
                        <label>Account Settings</label>
                        <label style={{ color: "#4a5363", fontSize: "12px" }}>Configure the required acces to the user</label>
                      </div>
                      <Divider style={{ borderStyle: 'dotted', width: '81.5%', marginLeft: "20px" }} />
                      <Grid
                        className="fieldsStyle"
                        container
                        spacing="20px"
                      >
                        <Grid item xs={1.1}>
                          <OZFCheckbox
                            name='enabled'
                            label='Enabled'
                            labelPosition="end"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={1.1}>
                          <OZFCheckbox
                            name='accountExpired'
                            label='Expired'
                            labelPosition="end"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={1.1}>
                          <OZFCheckbox
                            name='accountLocked'
                            label='Locked'
                            labelPosition="end"
                            disabled={disableForm}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <OZFCheckbox
                            name='credentialsExpired'
                            label='Password Expired'
                            labelPosition="end"
                            disabled={disableForm}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {(propsForm?.values?.appProfileUser != null) &&  (getAccessToShowConfig(propsForm?.values?.appProfileUser, 'Campaigns') || getAccessToShowConfig(propsForm?.values?.appProfileUser,'Agents') || getAccessToShowConfig(propsForm?.values?.appProfileUser,'Skills')) && (
                      <>
                        <div
                          style={{
                            paddingTop: "32px",
                            color: "#212121",
                            fontSize: "18px",
                            display: "flex",
                            flexDirection: "column",
                            paddingLeft: "20px",
                            lineHeight: "21px",
                            fontWeight: 400,
                          }}
                        >
                          <label>Configuration Access</label>
                          <label style={{ color: "#4a5363", fontSize: "12px" }}>Configure the required acces to the user</label>
                        </div>
                        <Divider style={{ borderStyle: 'dotted', width: '81.5%', marginLeft: "20px" }} />
                        <Grid xs={12} className="fieldsStyle" container spacing={2}>
                          {getAccessToShowConfig(propsForm?.values?.appProfileUser, 'Campaigns') &&
                          <Grid item xs={3.4}>
                            <CustomAutoComplete
                              mode='Add'
                              limitTags={2}
                              name="userCampaigns"
                              labelHeader='Campaigns'
                              placeholder={"Please Select"}
                              Datas={campaignListData}
                              optionLabelName='campaignName'
                              objId='campaignId'
                              showCheckBox={true}
                              multiple={true}
                              showselectall="true"
                              popupIcon={null}
                              disabled={disableForm}
                            />
                            {propsForm?.values?.userCampaigns?.length > 0 && (
                              <div style={styles.box}>
                                <div style={styles.arrow}></div>
                                <p style={styles.text}>Sub User will have edit access for selected Campaigns</p>
                              </div>
                            )}
                          </Grid>
                          }
                          {getAccessToShowConfig(propsForm?.values?.appProfileUser, 'Skills') &&
                            <Grid item xs={3.4}>
                              <CustomAutoComplete
                                mode='Add'
                                limitTags={3}
                                name="userSkills"
                                labelHeader='Skills'
                                placeholder={"Please Select"}
                                Datas={skillsListData}
                                optionLabelName='skillName'
                                // objId='campaignId'
                                showCheckBox={true}
                                multiple={true}
                                showselectall="true"
                                popupIcon={null}
                                disabled={disableForm}
                              />
                              {propsForm?.values?.userSkills?.length > 0 && (
                                <div style={styles.box}>
                                  <div style={styles.arrow}></div>
                                  <p style={styles.text}>Sub User will have edit access for selected Skills</p>
                                </div>
                              )}
                            </Grid>
                          }
                          {getAccessToShowConfig(propsForm?.values?.appProfileUser, 'Agents') &&
                          <Grid item xs={3.4}>
                            <CustomAutoComplete
                              mode='Add'
                              limitTags={2}
                              name="userGroups"
                              labelHeader='Agent Groups'
                              placeholder={"Please Select"}
                              Datas={agentGrpListData}
                              optionLabelName='name'
                              showCheckBox={true}
                              multiple={true}
                              showselectall="true"
                              popupIcon={null}
                              disabled={disableForm}
                            />
                            {propsForm?.values?.userGroups?.length > 0 && (
                              <div style={styles.box}>
                                <div style={styles.arrow}></div>
                                <p style={styles.text}>Sub User will have edit access for selected Agents</p>
                              </div>
                            )}
                          </Grid>
                          }
                        </Grid>
                        {/* <div
                              style={{
                                paddingTop: "32px",
                                color: "#212121",
                                fontSize: "18px",
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: "20px",
                                lineHeight: "21px",
                                fontWeight: 400,
                              }}
                            >
                              <label>Inbound Data Access</label>
                              <label style={{color: "#4a5363", fontSize: "12px"}}>Configure the required acces to the user</label>
                            </div>
                            <Divider style={{borderStyle: 'dotted'}}/>
                            <Grid
                              className="fieldsStyle"
                              container
                              spacing="20px"
                            >
                              <Grid item xs={5}>
                                <OZSelect
                                  name="accessLevel"
                                  label="Choose Access Level"
                                  // options={["Popup Mode", "Embed Mode", "Open in New Tab"]}
                                  placeholder="Select"
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <OZSelect
                                  name="agentGroups"
                                  label="Choose Agent Groups"
                                  // options={["Popup Mode", "Embed Mode", "Open in New Tab"]}
                                  placeholder="Select"
                                />
                              </Grid>
                            </Grid>
                            <div
                              style={{
                                paddingTop: "32px",
                                color: "#212121",
                                fontSize: "18px",
                                display: "flex",
                                flexDirection: "column",
                                paddingLeft: "20px",
                                lineHeight: "21px",
                                fontWeight: 400,
                              }}
                            >
                              <label>Outbound Data Access</label>
                              <label style={{color: "#4a5363", fontSize: "12px"}}>Configure the required acces to the user</label>
                            </div>
                            <Divider style={{borderStyle: 'dotted'}}/>
                            <Grid
                              className="fieldsStyle"
                              container
                              spacing="20px"
                            >
                              <Grid item xs={5}>
                                <OZSelect
                                  name="outboundAccessLevel"
                                  label="Choose Access Level"
                                  // options={["Popup Mode", "Embed Mode", "Open in New Tab"]}
                                  placeholder="Select"
                                />
                              </Grid>
                              <Grid item xs={5}>
                                <OZSelect
                                  name="chooseCampaign"
                                  label="Choose Campaign"
                                  // options={["Popup Mode", "Embed Mode", "Open in New Tab"]}
                                  placeholder="Select"
                                />
                              </Grid>
                            </Grid> */}
                      </>
                    )}
                    <Grid
                      container
                      style={{ paddingTop: "43px", paddingRight: "20px" }}
                      justifyContent="end"
                    >
                      <Grid item>
                        <Button
                          onClick={props?.onClose}
                          variant="outlined"
                          className="cancelButton"
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => {
                            propsForm.handleSubmit();
                          }}
                          variant="contained"
                          className="nextButton"
                          disabled={disableForm}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <ScrollToFieldError />
            </form>
          )}
        </Formik>
      </div>
    );
  }

  return (
    <div>
      <div>
        <DataForm {...props} />
      </div>
      {loading &&
        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <LoadingScreen />
        </div>
      }
    </div>
  );
};

export default AddSubUser;