import React, { useCallback, useEffect, useMemo } from 'react';
import { getDynamicWidgetAPI, getWidgetDetails, getsubadmin } from '../../../services/api-service';
import { Badge, Box, Chip, Stack, Grid, TextField, FormControl, Select, OutlinedInput, MenuItem, SelectChangeEvent, InputLabel, Autocomplete, Button, IconButton, Typography, Checkbox, FormControlLabel } from '@mui/material';
import WidgetDownload from '../../Widget-Download/Widget-Download';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { ReactComponent as FilterAltOutlinedIcon } from '../../../static/icons/filter.svg';
import Card from '@mui/material/Card';
import ThresholdDrawer from '../../ThresholdDrawer/ThresholdDrawer';
// import OZTable from '../../OZTable/OZTable';
import FilterDrawer from '../../FilterDrawer/FilterDrawer';
import { showErrorNotification } from '../../admin/common/NotiService';
import { Theme, useTheme } from '@mui/material/styles';
import { ReactComponent as RefreshOutlinedIcon } from '../../../static/icons/refresh.svg';
import { getLastFetchedDetail } from '../../../services/application-service';
import { checkRoles } from '../../../utils/util';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-alpine.css"
import { ReactComponent as DeleteIcon } from '../../../static/icons/deletenew.svg';
import './NewDynamicWidget.css'

let interval: any;

// const thresholdFields = [
//     { label: 'Longest wait time', value: '', key: 'LongestWaitTime', condition: 'GREATER_THAN' },
//     { label: 'Calls Waiting', value: '', key: 'QueueCount', condition: 'GREATER_THAN' }
// ];

const checkForFilterId = (label: any) => {
    switch (label) {
        case "Campaign":
            return 1;
        case "Skill":
            return 2;
        case "Group":
            return 3;
        case "Location":
            return 4;
        case "Detail":
            return 5;
        case "Detail2":
            return 6;
        case "Detail3":
            return 7;
        default:
            return 1;
    }
};

const NewDynamicWidget = (props: any) => {
    // console.log("here props comess", props);
    const { useState, useRef } = React;
    const theme = useTheme();
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean | undefined>(false);
    // const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || 1);
    const [filterType, setFilterType] = useState<any>(props?.selectedFilters?.filterSection?.id || checkForFilterId(props?.selectedFilters?.filterSelectionToShow ? props?.selectedFilters?.filterSelectionToShow[0] : 'Campaign'));
    const [filterSection, setFilterSection] = useState<any>(props?.selectedFilters?.filterSection || {});
    const [responseLength, setresponseLength] = useState<Number>(0);
    const [rowData, setRowData] = useState<Array<any>>([]);
    const [columnData, setColumnData] = useState<Array<any>>([]);
    const [thresholdItems, setThresholdItems] = useState<Array<any>>([]);
    const [dynamicWidgetFilter, setDynamicWidgetFilter] = useState<Array<any>>(props?.selectedFilters?.filters || []);
    const [isThresholdDrawerOpen, setIsThresholdDrawerOpen] = useState<boolean | undefined>(false);
    const downloadContent = useRef<any>(null);


    const [value1, setValue1] = useState(props?.selectedFilters?.procedureId || '');
    const [value2, setValue2] = useState(props?.selectedFilters?.dynamicHeaders || []);
    const [value3, setValue3] = useState(props?.selectedFilters?.headersToMap || '');
    const [selectedHeader, setSelectedHeader] = useState<string | null | any>('');
    const [newHeaderMap, setNewHeaderMap] = useState<Array<any>>(props?.selectedFilters?.headersToMap ? JSON.parse(props?.selectedFilters?.headersToMap) : [] || [])
    // const [filterToShow, setFilterToShow] = useState<any>(props?.selectedFilters?.filterSelectionToShow ? props?.selectedFilters?.filterSelectionToShow[0] : ['Campaign']);
    const [filterToShow, setFilterToShow] = useState<any>(props?.selectedFilters?.filterSelectionToShow || ['Campaign']);

    const procedureId = useRef<any>(props?.selectedFilters?.procedureId || '');
    const tempHeaders = useRef<any>(props?.selectedFilters?.dynamicHeaders || '');
    const tempHeadersToMap = useRef<any>(props?.selectedFilters?.headersToMap || '');
    const [showFilters, setShowFilters] = useState<any>(props?.enableWidgetEdit || false);
    const [toggleState, setToggleState] = useState<any>(false);
    const [lastFetchedTime, setLastFetchedTime] = useState<any>('00:00');

    const [widgetTitleName, setWidgetTitleName] = useState<any>(props?.selectedFilters?.widgetTitleName || 'Dynamic Widget');
    const [apiParams, setApiParams] = useState<any>([]);
    const [selectedWidget, setSelectedWidget] = useState<any>(null);
    const [extraParameters, setExtraParameters] = useState<any>([]);
    const [inputValues, setInputValues] = useState<{ [key: string]: string }>(props?.selectedFilters?.extraParams || {});
    const [finalRefreshTime, setFinalRefreshTime] = useState<any>(props?.selectedFilters?.refreshInterval || 0);

    const tempFinalRefresh = useRef<any>(props?.selectedFilters?.refreshInterval || 0);

    const [usersList, setUsersList] = useState<any>([]);
    const selectedSubUserId = useRef<any>(props?.selectedFilters?.subUserId || '');
    const [selectedSubUser, setSelectedSubUser] = useState<any>([]);
    let dynamicWidget = true;

    const names = [
        'Campaign',
        'Skill',
        'Group',
        'Location',
        'Detail',
        'Detail2',
        'Detail3',
    ];

    const refreshTimeDropDown = [
        {value: 0, label: 'Manual Refresh'},
        {value: 5, label: '5 sec'},
        {value: 10, label: '10 sec'},
        {value: 15, label: '15 sec'},
        {value: 20, label: '20 sec'},
        {value: 25, label: '25 sec'},
        {value: 30, label: '30 sec'},
        {value: 35, label: '35 sec'},
        {value: 40, label: '40 sec'},
        {value: 45, label: '45 sec'},
        {value: 50, label: '50 sec'},
        {value: 55, label: '55 sec'},
        {value: 60, label: '1 min'},
        {value: 300, label: '5 min'},
        {value: 600, label: '10 min'},
        {value: 1200, label: '20 min'},
        {value: 1800, label: '30 min'},
    ];

    let tempColumns: any = [];

    // const [checked, setChecked] =  useState<any>(false);

    const handleDownload = (type: string) => {
        // console.log(type);
    };

    const toggleDrawer = (isOpen: boolean) => {
        setIsDrawerOpen(isOpen);
    };

    let box = useMemo(() => ({ maxHeight: '410px', overflowY: 'auto', padding: '15px', marginLeft: '5px', backgroundColor: 'white', borderRadius: '8px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0)' }), []);
    const deleteHeader = (headerName: string) => {
        setNewHeaderMap((prev) => prev.filter((header) => header.headerName !== headerName));
    };

    // const updateHeader = (headerName: string, newAlias: string) => {
    //     setNewHeaderMap((prev) =>
    //         prev.map((header) =>
    //             header.headerName === headerName ? { ...header, alias: newAlias } : header
    //         )
    //     );
    // };

    const updateHeader = (index: any, newHeaderName: any, newAlias: any) => {
        setNewHeaderMap((prev) =>
            prev.map((header, i) =>
                i === index
                    ? { ...header, headerName: newHeaderName || header.headerName, alias: newAlias || header.alias }
                    : header
            )
        );
    };

    // const addHeader = (headerName: string | null) => {

    //     if (headerName) {
    //         if (headerName === 'All') {
    //             if (newHeaderMap.length !== value2.length - 1) {
    //                 let newHeaders = value2.filter((item: any) => item !== headerName).map((header: any) => {
    //                     return { headerName: header, alias: '' }
    //                 })
    //                 setNewHeaderMap(newHeaders);
    //             } else {
    //                 alert('All Headers already added.');
    //             }
    //         } else {
    //             const headerExists = newHeaderMap.some((header) => header.headerName.toLowerCase() === headerName.toLowerCase())
    //             if (headerExists) {
    //                 alert('Header with this name already exists.');
    //             } else {
    //                 let newHeader = { headerName: headerName, alias: '' }
    //                 setNewHeaderMap((prev) => [...prev, newHeader]);
    //             }
    //         }
    //         setSelectedHeader(null)
    //     }
    //     // else{
    //     //     let newHeader = { headerName: "", alias: '' }
    //     //     setNewHeaderMap((prev) => [...prev, newHeader]);
    //     // }
    // };

    const addHeader = (headerName = '') => {
        if (headerName) {
            // Handle "All" as in your existing logic
            if (headerName === 'All') {
                if (newHeaderMap.length !== value2.length - 1) {
                    let newHeaders = value2.filter((item: any) => item !== 'All').map((header: any) => ({
                        headerName: header,
                        alias: '',
                    }));
                    setNewHeaderMap(newHeaders);
                } else {
                    alert('All Headers already added.');
                }
            } else {
                // Handle unique headers
                const headerExists = newHeaderMap.some((header) => header.headerName.toLowerCase() === headerName.toLowerCase());
                if (headerExists) {
                    alert('Header with this name already exists.');
                } else {
                    let newHeader = { headerName, alias: '' };
                    setNewHeaderMap((prev) => [...prev, newHeader]);
                }
            }
            setSelectedHeader(null);
        } else {
            let newManualHeader = { headerName: '', alias: '' };
            setNewHeaderMap((prev) => [...prev, newManualHeader]);
        }
    };

    useEffect(() => {
        makingColumn()
        if (newHeaderMap.length === 0) {
            setColumnData([])
        }
        props?.handleFilterChange?.(dynamicWidgetFilter, filterSection, props?.widgetId, value1, value2, JSON.stringify(newHeaderMap), filterToShow, widgetTitleName, inputValues, tempFinalRefresh.current,selectedSubUserId.current, dynamicWidget);
    }, [newHeaderMap, filterToShow, inputValues])


    // async function TotalHeadersData(id: any, filters: any = '0') {
    //     if (value1 === '' && procedureId.current === '') return;
    //     const createZeroedInputValues = (inputValues: any) => {
    //         const zeroedValues: any = {};
    //         Object.keys(inputValues).forEach(key => {
    //             zeroedValues[key] = '0';
    //         });
    //         return zeroedValues;
    //     };
    //     tempColumns = [];
    //     const params: any = { FilterId: 0, Type: filterType, apiId: id,UserId: 0, ...createZeroedInputValues };
    //     try {
    //         console.log("here params going  are ", params);
    //         let data: any = await getDynamicWidgetAPIForHeaders(params);
    //         console.log("here values are ", data);
    //         // let tempK: any = '';
    //         // if (data && data?.length > 0) {
    //         //     // tempK = Object.keys(data[0]).join(',');
    //         //     tempK = Object.keys(data[0]).join(',');
    //         // }
    //         // setValue2(['All', ...Object.keys(data[0])]);
    //         // tempHeaders.current = tempK;

    //         if (data && data[0]?.ColumnSpec) {
    //             const columnsArray = data[0]?.ColumnSpec.split(',');
    //             setValue2(['All', ...columnsArray]);
    //             tempHeaders.current = columnsArray.join(',');
    //         }
        
    //         // setRowData(data);
    //         // makingColumn();
    //     }
    //     catch (e) {
    //         // showErrorNotification('Error occured, Please try again later');
    //     }
    //     setLastFetchedTime(getLastFetchedDetail());
    // }


    async function getTableCall(id: any, filters: any = '0') {
        if (value1 === '' && procedureId.current === '') return;
        tempColumns = [];
        const params: any = { FilterId: filters, Type: filterType, apiId: id, ...inputValues };
        try {
            let data: any = await getDynamicWidgetAPI(params);
            if(data?.length > 0){
                let tempK: any = '';
                if (data && data?.length > 0) {
                    tempK = Object.keys(data[0]).join(',');
                }
                setValue2(['All', ...Object.keys(data[0])]);
                tempHeaders.current = tempK;
                setRowData(data);
                makingColumn();
            }else{
                tempHeaders.current = "";
                setValue2([]);
                setRowData([]);
                makingColumn();
            }
        }
        catch (e) {
            // showErrorNotification('Error occured, Please try again later');
        }
        setLastFetchedTime(getLastFetchedDetail());
    }

    // const handleKeyDownProcedureId = (event: any) => {
    //     if (event.key === 'Enter') {
    //         setValue1(value1);
    //         procedureId.current = value1;
    //         props?.handleFilterChange?.(dynamicWidgetFilter, filterSection, props?.widgetId, procedureId.current, value2, value3, filterToShow, widgetTitleName);
    //     }
    // };

    const handleChangeProcedureId = (event: any) => {
        setInputValues({});
        setValue2([])
        setSelectedHeader(null)
        setNewHeaderMap([])
        setColumnData([])
        const selected = apiParams.find((widget: any) => widget.widgetID === event.target.value);
        setSelectedWidget(selected);

        setValue1(String(event.target.value));
        procedureId.current = String(event.target.value);
        props?.handleFilterChange?.(dynamicWidgetFilter, filterSection, props?.widgetId, procedureId.current, value2, value3, filterToShow, widgetTitleName, inputValues, tempFinalRefresh.current,selectedSubUserId.current, dynamicWidget);
    };

    const handleChangeUsersList = (event: any) => {
        const selectedUsers = event.target.value || [];

        setSelectedSubUser(selectedUsers);
        const selectedUserIds = selectedUsers.map((user: any) => user.id).join(',');
        selectedSubUserId.current = selectedUserIds;
        // Check if "Select All" is selected
        // let selectedUserIds : any = "";
        // if (selectedUsers.some((user: any) => user.id === 'selectAll')) {
        //     if (selectedSubUser.length === usersList.length) {
        //         // If all are selected, deselect all
        //         setSelectedSubUser([]);
        //         selectedSubUserId.current = '';
        //     } else {
        //         const filteredSelectedUsers = usersList.filter((user: any) => user.id !== 'selectAll');
        //         setSelectedSubUser(usersList);
        
        //         selectedUserIds = filteredSelectedUsers.map((user: any) => user.id).join(',');
        //         selectedSubUserId.current = selectedUserIds;
        //     }
        // } else {
        //     // Normal selection case (without "Select All")
        //     const filteredSelectedUsers = selectedUsers.filter((user: any) => user.id !== 'selectAll');
        //     setSelectedSubUser(selectedUsers);
    
        //     selectedUserIds = filteredSelectedUsers.map((user: any) => user.id).join(',');
        //     selectedSubUserId.current = selectedUserIds;
        // }
        // Check if "Select All" is selected
        props?.handleFilterChange?.(dynamicWidgetFilter, filterSection, props?.widgetId, procedureId.current, value2, value3, filterToShow, widgetTitleName, inputValues, tempFinalRefresh.current,selectedUserIds, dynamicWidget);
    };


    const unwantedParams = ["FilterId", "UserId", "Type"];

    const handleInputChange = (param: string, event: any) => {
        setInputValues(prev => ({
            ...prev,
            [param]: event.target.value,
        }));
    };

    const getAllLeftParams = (id: any) => {
        if (id && id?.widgetParams) {
            const params = id.widgetParams.split(',').filter((param: any) => !unwantedParams.includes(param));
            setExtraParameters(params);

        }
    }

    async function getWidgetDetailsList() {
        setApiParams([]);
        try {
            getWidgetDetails("", "").then((resp: any) => {
                resp?.Data?.map((item: any) => {
                    if (item.widgetID === parseInt(props?.selectedFilters?.procedureId)) {
                        setSelectedWidget(item);
                    }
                })
                setApiParams(resp?.Data);
            }).catch(e => {
                setApiParams([])
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
        }
    }

    useEffect(() => {
        if (props?.selectedFilters?.subUserId) {
            const selectedIdsArray = props?.selectedFilters?.subUserId?.split(',').map(Number);

            const preselectedUsers = usersList?.filter((user: any) => selectedIdsArray?.includes(user?.id));

            setSelectedSubUser(preselectedUsers);
        }
    }, [props?.selectedFilters?.subUserId, usersList]);

    async function getSubUserList() {
        setUsersList([]);
        try {
            getsubadmin().then((resp: any) => {
                setUsersList(resp?.Data);
                // setUsersList([{ username: 'Select All', id: 'selectAll' }, ...resp?.Data]);
            }).catch(e => {
                setUsersList([])
            })
        }
        catch (e) {
            showErrorNotification(JSON.stringify(e));
            setUsersList([])
        }
    }

    useEffect(() => {
        getAllLeftParams(selectedWidget);
    }, [selectedWidget]);

    const handleFilterDelete = (id: Number) => {
        const newFilter = dynamicWidgetFilter.filter((item: any) => item.id !== id);
        setDynamicWidgetFilter(newFilter);
        props?.handleFilterChange?.(newFilter, filterSection, props?.widgetId, value1, value2, value3, filterToShow, widgetTitleName, inputValues, tempFinalRefresh.current,selectedSubUserId.current, dynamicWidget);
    };

    const clearFilter = () => {
        setDynamicWidgetFilter([]);
        props?.handleFilterChange?.([], filterSection, props?.widgetId, value1, value2, value3, filterToShow, widgetTitleName, inputValues, tempFinalRefresh.current,selectedSubUserId.current, dynamicWidget);
    };

    const handleFilterExistingData = (filteredData: any) => {
        setDynamicWidgetFilter(filteredData);
        props?.handleFilterChange?.(filteredData, filterSection, props?.widgetId, value1, value2, value3, filterToShow, widgetTitleName, inputValues, tempFinalRefresh.current,selectedSubUserId.current, dynamicWidget);
    };

    // const handleChangeValue1 = (data: any) => {
    //     setValue1(data);
    //     procedureId.current = data;
    // }

    // const handleChangeValue2 = (data: any) => {
    //     setValue2(data);
    //     tempHeaders.current = data;
    //     props?.handleFilterChange?.(dynamicWidgetFilter, filterSection, props?.widgetId, value1, tempHeaders.current, value3, filterToShow, widgetTitleName, inputValues);
    // }

    const handleChangeRefreshTime = (data: any) => {
        setFinalRefreshTime(data);
        tempFinalRefresh.current = data;
        props?.handleFilterChange?.(dynamicWidgetFilter, filterSection, props?.widgetId, value1, value2, JSON.stringify(newHeaderMap), filterToShow, widgetTitleName, inputValues, data,selectedSubUserId.current, dynamicWidget);
        // resetInterval(data);
        resetInterval(tempFinalRefresh.current);
    };

    // useEffect(() => {//for checkbox i am doing it 
    //     if(checked === false || checked === "false"){
    //         console.log("i came here ");
    //         tempFinalRefresh.current = 0;
    //         setFinalRefreshTime(0);
    //         props?.handleFilterChange?.(dynamicWidgetFilter, filterSection, props?.widgetId, value1, value2, JSON.stringify(newHeaderMap), filterToShow, widgetTitleName, inputValues, tempFinalRefresh.current);
    //         resetInterval(tempFinalRefresh.current);
    //     }
    // }, [checked]);

    // const handleChangeCheckBox = (event: any) => {//for checkbox i am doing it 
    //     console.log("yes here ", event.target.checked);
    //     setChecked(event.target.checked);
    // };

    const resetInterval = (data: any) => {
        if (interval) {
            clearInterval(interval);
        }
        const filterIds = (dynamicWidgetFilter.length && !(responseLength === dynamicWidgetFilter.length))
            ? dynamicWidgetFilter.map((item: any) => item.id).join(',')
            : '0';

        if (parseInt(data) > 0) {
            // interval = setInterval(() => {TotalHeadersData(procedureId.current, filterIds) ; getTableCall(procedureId.current, filterIds)}, parseInt(data) * 1000);
            interval = setInterval(() => getTableCall(procedureId.current, filterIds), parseInt(data) * 1000);
        }
    };

    const handleChangeTitleName = (data: any) => {
        setWidgetTitleName(data);
        props?.handleFilterChange?.(dynamicWidgetFilter, filterSection, props?.widgetId, value1, tempHeaders.current, value3, filterToShow, data, inputValues, tempFinalRefresh.current,selectedSubUserId.current, dynamicWidget);
    }

    // const handleChangeValue3 = (data: any) => {
    //     console.log("here data coming ", data);
    //     setValue3(data);
    //     tempHeadersToMap.current = data;
    //     makingColumn();
    //     props?.handleFilterChange?.(dynamicWidgetFilter, filterSection, props?.widgetId, value1, value2, tempHeadersToMap.current, filterToShow, widgetTitleName, inputValues);
    // }

    const makingColumn = () => {
        if (newHeaderMap.length > 0) {
            let tempC: any = [];
            newHeaderMap.map((item: any, index: any) => {
                tempC.push({ field: `${item.headerName}`, headerName: `${item.alias ? item.alias : item.headerName}`, name: `${item.alias ? item.alias : item.headerName}`, key: `${item.headerName}`, minWidth: 120 });
                // tempC.push({ field: `${item.headerName}`, headerName: `${item.alias ? item.alias : item.headerName}`, name: `${item}`, minWidth: 120 });
            })
            setColumnData(tempC)
        }
    }

    const handleKeyDownTitle = (event: any) => {
        if (event.key === 'Enter') {
            setWidgetTitleName(widgetTitleName);
        }
    };

    const handleApplyFilters = ({ selectedFilters, filterType: newFilterType, filterSection: newfilterSection }: any) => {
        setFilterType(newFilterType);
        setFilterSection(newfilterSection);
        setDynamicWidgetFilter(selectedFilters);
        // props?.handleFilterChange?.(selectedFilters, filterSection, props?.widgetId, value1, value2, value3, filterToShow, widgetTitleName, inputValues, tempFinalRefresh.current,selectedSubUserId.current, dynamicWidget);
        toggleDrawer(false);
        props?.handleFilterChange?.(selectedFilters, newfilterSection, props?.widgetId, value1, value2, value3, filterToShow, widgetTitleName, inputValues, tempFinalRefresh.current,selectedSubUserId.current, dynamicWidget);
    };

    const filterItems = dynamicWidgetFilter.map((item: any, index: number) => (
        <Chip key={index} label={item.label} color="primary" variant="outlined" onDelete={() => handleFilterDelete(item.id)} deleteIcon={<CloseOutlinedIcon style={{ fontSize: '12px' }} />} />
    ));

    const toggleThresholdDrawer = (isOpen: boolean, data: any = null) => {
        setIsThresholdDrawerOpen(isOpen);
        if (data) {
            setThresholdItems(data);
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    function getStyles(name: string, filterToShow: readonly string[], theme: Theme) {
        return {
            fontWeight:
                filterToShow.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const handleChange = (event: SelectChangeEvent<typeof filterToShow>) => {
        const {
            target: { value },
        } = event;
        setFilterToShow(
            typeof value === 'string' ? value.split(',') : value,
        );
        props?.handleFilterChange?.(dynamicWidgetFilter, filterSection, props?.widgetId, value1, tempHeaders.current, JSON.stringify(newHeaderMap), value, widgetTitleName, inputValues, tempFinalRefresh.current,selectedSubUserId.current, dynamicWidget);
        // props?.handleFilterChange?.(dynamicWidgetFilter, filterSection, props?.widgetId, value1, value2, value3, value);
    };

    React.useEffect(() => {
        const filterIds = (dynamicWidgetFilter.length && !(responseLength === dynamicWidgetFilter.length)) ? dynamicWidgetFilter.map((item: any) => item.id).join(',') : '0';
        // TotalHeadersData(procedureId.current, filterIds);
        getTableCall(procedureId.current, filterIds);
        if (interval) {
            clearInterval(interval);
        }
        resetInterval(tempFinalRefresh.current);
    }, [dynamicWidgetFilter, thresholdItems]);

    useEffect(() => {
        getWidgetDetailsList();
        getSubUserList();
        return () => {
            clearInterval(interval);
        };
    }, []);

    function getRowData(row: any) {
        return row;
    }

    const toggleRefresh = () => {
        const filterIds = (dynamicWidgetFilter.length && !(responseLength === dynamicWidgetFilter.length)) ? dynamicWidgetFilter.map((item: any) => item.id).join(',') : '0';
        setToggleState(true);
        // TotalHeadersData(procedureId.current, filterIds);
        getTableCall(procedureId.current, filterIds);
    };

    const defaultColDef = useMemo(() => {
        return {
            filter: 'agTextColumnFilter',
            // floatingFilter: true,
            cellClass: "dynamic-widget-cell-center",
            headerClass: 'dynamic-widget-header-center',
            flex: 1,
            // minWidth: 200,
            // wrapHeaderText: true,

        };
    }, []);

    const getRowClass = useCallback((params: any) => {
        if (params.node.rowIndex % 2 !== 0) {
            return 'odd-row';
        } else {
            return '';
        }
    }, []);

    return (
        <Grid ref={downloadContent} container xs={12} direction='row'>
            <Grid item xs={(showFilters && checkRoles("ROLE_ADMIN")) ? 7.5 : 12} flexGrow={1}>
                <Card className="customCard">
                    <div className="card-header">
                        <div className="card-title">
                            {/* Dynamic Widget */}
                            {widgetTitleName}
                            {parseInt(tempFinalRefresh.current) > 0 && <div className="card-title-badge">Live</div>}
                            {parseInt(tempFinalRefresh.current) <= 0 &&
                                <>
                                    <div className="refresh-btn card-icon" onClick={() => toggleRefresh()}>
                                        <RefreshOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
                                    </div>
                                    <span className="last-fetched-time">Last fetched at: {lastFetchedTime} </span>
                                </>
                            }
                        </div>
                        <div className="card-actions">
                            <WidgetDownload element={downloadContent} handleDownload={handleDownload} xlsx rowData={getRowData(rowData)} columns={columnData} name={'Dynamic Widget'}></WidgetDownload>
                            <div className="card-icon" onClick={() => toggleDrawer(true)}>
                                <Badge badgeContent={dynamicWidgetFilter.length} color="primary">
                                    <FilterAltOutlinedIcon fontSize="small" style={{ color: '#536580' }} />
                                </Badge>
                            </div>
                        </div>
                    </div>
                    <FilterDrawer DynamicWidget isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} applyFilters={handleApplyFilters} existingSelectedFilters={dynamicWidgetFilter} allowedFilters={filterToShow} setResponseLength={setresponseLength} existingFilterCategory={filterType} applyFilterOnExistingData={handleFilterExistingData} />
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        {dynamicWidgetFilter.length > 0 && (
                            <Box className='customTabPanel'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <div className="filter-outer">
                                            <div className="filter-container">
                                                <Stack direction="row" spacing={1}>
                                                    {filterItems}
                                                </Stack>
                                            </div>
                                            <div className="filter-remove" onClick={clearFilter}>
                                                Remove
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                        <div style={{ height: 365 }}>
                            <AgGridReact
                                ref={downloadContent}
                                className="ag-theme-alpine"
                                defaultColDef={defaultColDef}
                                rowData={newHeaderMap.length > 0 ? rowData : []}
                                columnDefs={columnData}
                                rowHeight={38}
                                headerHeight={39}
                                getRowClass={getRowClass}
                            />
                        </div>

                        {/* <OZTable columns={columnData} rows={rowData} maxHeight={360} downloadContent={downloadContent}></OZTable> */}
                    </Box>
                    <ThresholdDrawer isOpen={isThresholdDrawerOpen} title={'Dynamic Widget'} toggleDrawer={toggleThresholdDrawer} thresholdItems={thresholdItems} />
                </Card>
            </Grid>
            {showFilters && checkRoles("ROLE_ADMIN") &&
                <Grid item xs={4.5}>
                    <Box sx={box}>
                        <Grid direction={'column'} container gap={2} >
                            <Grid item >
                                <TextField
                                    id="title-name"
                                    label="Widget Name"
                                    variant="outlined"
                                    onChange={(e) => handleChangeTitleName(e.target.value)}
                                    value={widgetTitleName}
                                    onKeyDown={handleKeyDownTitle}
                                    fullWidth
                                    size='small'
                                    sx={{ marginBottom: '10px', marginTop: '5px' }}
                                />
                            </Grid  >
                            <Grid item >
                                <Autocomplete
                                    options={apiParams}
                                    getOptionLabel={(option) => option.widgetName}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeProcedureId({
                                            target: { value: newValue ? newValue.widgetID : '' },
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Procedure Name"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.widgetID}>
                                            {option.widgetName}
                                        </li>
                                    )}
                                    value={apiParams.find((widget: any) => widget.widgetID === selectedWidget?.widgetID) || null}
                                    isOptionEqualToValue={(option: any, value: any) => option.widgetID === value.widgetID}
                                    filterSelectedOptions
                                    autoHighlight
                                    fullWidth
                                />
                                {/* <FormControlLabel
                                    control={
                                        <Checkbox
                                            onChange={handleChangeCheckBox}
                                            value={checked}
                                            // defaultChecked 
                                        />
                                    } 
                                    label="Select RefreshTime"
                                /> */}
                                {/* {checked &&
                                <>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id="refresh-time-label" sx={{ marginTop: '20px' }}>Refresh Time</InputLabel>
                                    <Select
                                        id="refresh-time"
                                        label="Refresh Time"
                                        variant="outlined"
                                        sx={{ marginBottom: '5px',marginTop: '20px' }}
                                        onChange={(e) => handleChangeRefreshTime(e.target.value)}
                                        value={finalRefreshTime}
                                        fullWidth
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: 200,
                                                },
                                            },
                                        }}
                                    >
                                        {refreshTimeDropDown.map((item: any) => (
                                            <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                </>
                                } */}
                                <Autocomplete
                                    multiple
                                    limitTags={2}
                                    options={usersList}
                                    getOptionLabel={(option) => option.username}
                                    onChange={(event: any, newValue: any) => {
                                        handleChangeUsersList({
                                            target: { value: newValue },
                                        });
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Sub-User"
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <span>{option.username}</span>
                                            </div>
                                        </li>
                                    )}
                                    value={selectedSubUser}
                                    isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                    filterSelectedOptions
                                    autoHighlight
                                    fullWidth
                                    sx={{ marginTop: '10px', marginBottom: '5px' }}
                                />
                            </Grid  >
                            <Grid container gap={1} direction='row' >
                                <Grid item md={7}>
                                    <Autocomplete
                                        // multiple
                                        // disableCloseOnSelect
                                        // limitTags={2}
                                        id="headers-values"
                                        size="small"
                                        value={selectedHeader}
                                        options={value2}
                                        getOptionLabel={(option: string) => option}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Headers" placeholder="Select headers" />
                                        )}
                                        onChange={(event: any, newValue: string | null) => {
                                            setSelectedHeader(newValue);
                                        }}

                                    />
                                </Grid >
                                <Grid item md={2} >
                                    <Button disabled={!selectedHeader} variant="outlined" onClick={() => { addHeader(selectedHeader) }}>Add</Button>
                                </Grid >
                                <Grid item md={2.5} >
                                    {/* <Button variant="outlined" onClick={() => { addHeader("") }}>Manual Headers</Button> */}
                                    <Button variant="outlined" onClick={() => { addHeader() }}>Manual Add</Button>
                                </Grid >
                            </Grid>
                            {newHeaderMap.length > 0 && <Grid container direction={'column'} sx={{ backgroundColor: 'rgba(237, 240, 242, 0.45)', borderRadius: '8px', padding: '10px' }} >

                                <Grid>
                                    <Typography fontWeight={500} color={"#545454"}>Map Headers</Typography>
                                </Grid>
                                {/* <Stack gap={1}  >
                                    {newHeaderMap.map((item, index) => {
                                        return (
                                            <Grid container gap={.5} key={item.headerName} alignItems={'center'}>
                                                <Grid item md={4}>
                                                    <Typography sx={{ wordBreak: 'break-all' }}>
                                                        {item.headerName}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={6}>
                                                    <TextField
                                                        size='small'
                                                        id={"header-" + item.headerName}
                                                        placeholder='alias'
                                                        // label="Headers to Map"
                                                        variant="outlined"
                                                        onBlur={(e) => updateHeader(item.headerName, e.target.value)}
                                                        defaultValue={item.alias}
                                                    // onKeyDown={handleKeyDown}
                                                    // fullWidth
                                                    />


                                                </Grid>
                                                <Grid item md={1}>

                                                    <IconButton onClick={() => { deleteHeader(item.headerName) }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Stack> */}

                                {/* new update Code */}
                                {/* <Stack gap={1}  >
                                    {newHeaderMap.map((item, index) => {
                                        return (
                                            <Grid container gap={.5} key={item.headerName} alignItems={'center'}>
                                                <Grid item md={4}>
                                                    {item.headerName &&
                                                    <Typography sx={{ wordBreak: 'break-all' }}>
                                                        {item.headerName}
                                                    </Typography>
                                                    }
                                                    {!item.headerName &&
                                                        <TextField
                                                            size="small"
                                                            placeholder="Header Name"
                                                            variant="outlined"
                                                            value={item.headerName}
                                                            onBlur={(e) => updateManualHeader(index, 'headerName', e.target.value)}
                                                        />
                                                    }
                                                </Grid>
                                                <Grid item md={6}>
                                                    <TextField
                                                        size='small'
                                                        id={"header-" + item.headerName}
                                                        placeholder='alias'
                                                        // label="Headers to Map"
                                                        variant="outlined"
                                                        onBlur={(e) => updateHeader(item.headerName, e.target.value)}
                                                        defaultValue={item.alias}
                                                    // onKeyDown={handleKeyDown}
                                                    // fullWidth
                                                    />


                                                </Grid>
                                                <Grid item md={1}>

                                                    <IconButton onClick={() => { deleteHeader(item.headerName) }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Stack> */}
                                {/* new update Code */}

                                <Stack gap={1}  >
                                    {newHeaderMap.map((item, index) => {
                                        return (
                                            <Grid container gap={.5} key={item.headerName} alignItems={'center'}>
                                                <Grid item md={4}>
                                                    <TextField
                                                        variant="outlined"
                                                        // onBlur={() => {
                                                        //     // Save and clear tempHeaderName on blur
                                                        //     saveHeaderName(index, tempHeaderName);
                                                        //     setTempHeaderName(""); // Reset temp state
                                                        // }}
                                                        onBlur={(e) => updateHeader(index, e.target.value, item.alias)}
                                                        defaultValue={item.headerName}
                                                        size="small"
                                                        sx={{ marginRight: '10px' }}
                                                    />
                                                </Grid>
                                                <Grid item md={6}>
                                                    <TextField
                                                        variant="outlined"
                                                        // onBlur={() => {
                                                        //     // Save and clear tempAlias on blur
                                                        //     saveAlias(index, tempAlias);
                                                        //     setTempAlias(""); // Reset temp state
                                                        // }}
                                                        onBlur={(e) => updateHeader(index, item.headerName, e.target.value)}
                                                        size="small"
                                                        defaultValue={item.alias}
                                                        sx={{ marginRight: '10px' }}
                                                    />
                                                </Grid>
                                                <Grid item md={1}>
                                                    <IconButton onClick={() => { deleteHeader(item.headerName) }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                </Stack>
                            </Grid>
                            }
                            <FormControl fullWidth size='small'>
                                <InputLabel id="refresh-time-label" sx={{ marginTop: '20px' }}>Refresh Time</InputLabel>
                                <Select
                                    id="refresh-time"
                                    label="Refresh Time"
                                    variant="outlined"
                                    sx={{ marginBottom: '5px',marginTop: '20px' }}
                                    onChange={(e) => handleChangeRefreshTime(e.target.value)}
                                    value={finalRefreshTime}
                                    fullWidth
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200,
                                            },
                                        },
                                    }}
                                >
                                    {refreshTimeDropDown.map((item: any) => (
                                        <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl fullWidth size='small'>
                                <InputLabel htmlFor='filter-type' id="filter-type-label">Filter Type</InputLabel>
                                <Select
                                    labelId="filter-type-label"
                                    id="filter-type"
                                    multiple
                                    label='Filter Type'
                                    value={filterToShow}
                                    onChange={handleChange}
                                    renderValue={(selected) => (
                                        <Box  >
                                            {selected.map((value: any) => (
                                                <Chip key={value} label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {names.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            style={getStyles(name, filterToShow, theme)}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {extraParameters.map((param: any, index: any) => (
                                <Grid container key={index} gap={2}>
                                    <Grid item md={5}>
                                        <Select
                                            value={param}
                                            input={<OutlinedInput />}
                                            disabled
                                            fullWidth
                                            size='small'
                                        >
                                            <MenuItem value={param}>{param}</MenuItem>
                                        </Select>
                                    </Grid>

                                    <Grid item >
                                        <TextField
                                            size='small'
                                            label={`Enter ${param}`}
                                            variant="outlined"
                                            value={inputValues[param] || ""}
                                            onChange={(e) => handleInputChange(param, e)}
                                        />
                                    </Grid>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Grid>
            }
        </Grid>
    );
}

export default NewDynamicWidget;
